'use client'

import { createContext, useContext, ReactNode } from 'react'
import { Page } from '@/types'

export const PageContext = createContext<Page.Page>({} as Page.Page)

export function PageProvider({
  children,
  page,
}: {
  children: ReactNode
  page: Page.Page
}) {
  return <PageContext.Provider value={page}>{children}</PageContext.Provider>
}

export function usePage(): Page.Page {
  return useContext<Page.Page>(PageContext)
}
