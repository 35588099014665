import type { MenuStylesType } from '@material-tailwind/react'

const menu: MenuStylesType = {
  defaultProps: {
    placement: 'bottom',
    offset: 5,
    dismiss: {
      itemPress: true,
    },
    animate: {
      unmount: {},
      mount: {},
    },
    lockScroll: false,
  },
  styles: {
    base: {
      menu: {
        bg: 'bg-white',
        minWidth: 'min-w-[200px]',
        p: 'p-2',
        mt: 'mt-2',
        border: 'border border-border-color',
        borderRadius: 'rounded-none',
        boxShadow: 'shadow-lg shadow-blue-gray-500/10',
        fontFamily: 'font-poppins',
        fontSize: 'text-lg',
        fontWeight: 'font-normal',
        color: 'text-primary',
        overflow: 'overflow-auto',
        outline: 'focus:outline-none',
        zIndex: 'z-[333]',
      },
      item: {
        initial: {
          display: 'block',
          width: 'w-full',
          py: 'py-3',
          px: 'px-2',
          borderRadius: 'rounded-none',
          textAlign: 'text-start',
          lightHeight: 'leading-tight',
          cursor: 'cursor-pointer',
          userSelect: 'select-none',
          transition: 'transition-all',
          bg: 'hover:bg-blue-gray-50 hover:bg-opacity-80 focus:bg-blue-gray-50 focus:bg-opacity-80 active:bg-blue-gray-50 active:bg-opacity-80',
          color:
            'hover:text-blue-gray-900 focus:text-blue-gray-900 active:text-blue-gray-900',
          outline: 'outline-none',
        },
        disabled: {
          opacity: 'opacity-50',
          cursor: 'cursor-not-allowed',
          pointerEvents: 'pointer-events-none',
          userSelect: 'select-none',
          bg: 'hover:bg-transparent focus:bg-transparent active:bg-transparent',
          color:
            'hover:text-blue-gray-500 focus:text-blue-gray-500 active:text-blue-gray-500',
        },
      },
    },
  },
}

export default menu
