'use client'

import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'

export type LoaderContextType = {
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
}

export const LoaderContext = createContext<LoaderContextType>(
  {} as LoaderContextType
)

export function LoaderProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false)
  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoaderContext.Provider>
  )
}

export function useLoader(): LoaderContextType {
  return useContext<LoaderContextType>(LoaderContext)
}
