import type { IconButtonStyleTypes } from '@material-tailwind/react'

const iconButton: IconButtonStyleTypes = {
  defaultProps: {
    color: 'white',
  },
  styles: {
    sizes: {
      sm: {
        borderRadius: 'rounded-none',
      },
      md: {
        borderRadius: 'rounded-none',
      },
      lg: {
        borderRadius: 'rounded-none',
      },
    },
    variants: {
      filled: {
        white: {
          color: 'text-primary',
          shadow: 'shadow shadow-black/25',
          hover: 'hover:shadow-md hover:shadow-black/50',
          focus: 'focus:opacity-100 focus:shadow',
          active: 'active:opacity-100 active:shadow',
        },
      },
      gradient: {
        white: {
          color: 'text-primary',
          shadow: 'shadow shadow-black/25',
          hover: 'hover:shadow-md hover:shadow-black/50',
          focus: 'focus:opacity-100 focus:shadow',
          active: 'active:opacity-100 active:shadow',
        },
      },
    },
  },
}

export default iconButton
