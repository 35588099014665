import type { InputStylesType } from '@material-tailwind/react'

const input: InputStylesType = {
  defaultProps: {
    color: 'black',
  },
  styles: {
    base: {
      input: {
        bg: 'bg-white',
        color: 'text-black',
      },
    },
    variants: {
      outlined: {
        base: {
          input: {
            borderRadius: 'rounded-none',
            placeholder: 'opacity-100',
            borderWidth: 'border',
            borderColor: 'border-border-color',
            floated: {
              borderWidth: 'border focus:border',
              borderColor: '',
            },
          },
          label: {
            before: {
              content: 'before:content-none',
            },
            after: {
              content: 'after:content-none',
            },
          },
        },
        sizes: {
          md: {
            input: {
              fontSize: 'text-base',
            },
          },
          lg: {
            input: {
              fontSize: 'text-base',
            },
          },
        },
      },
      standard: {},
      static: {
        base: {
          input: {
            borderRadius: 'rounded-none',
            placeholder: 'opacity-100',
            borderWidth: 'border',
            borderColor: 'border-border-color',
            padding: 'pl-2.5',
            paddingRight: '!pr-10',
            paddingTop: '!pt-2.5',
            paddingBottom: '!pb-2.5',
            floated: {
              borderWidth: 'border focus:border',
              borderColor: '',
            },
          },
          label: {
            fontSize: 'text-base',
            top: '!top-0',
          },
        },
        sizes: {
          md: {
            container: {
              height: 'h-17',
              paddingTop: 'pt-6',
            },
            input: {
              fontSize: 'text-base',
              pt: 'pt-4',
              pb: 'pb-1.5',
            },
            icon: {
              width: 'w-10',
              height: 'h-10',
              top: 'top-auto',
              bottom: 'bottom-0',
              transform: '!-translate-y-0',
            },
            label: {
              fontSize: 'text-base',
              before: {
                content: 'before:content-none',
              },
              after: {
                content: 'after:content-none',
              },
            },
          },
          lg: {
            label: {
              fontSize: 'text-base',
              top: '!top-0',
            },
          },
        },
      },
    },
  },
}

export default input
