import type { AccordionStylesType } from '@material-tailwind/react'

const accordion: AccordionStylesType = {
  styles: {
    base: {
      header: {
        initial: {
          color: 'text-primary',
          fontSize: 'text-sm',
          fontFamily: 'font-poppins',
          fontWeight: 'font-normal',
        },
      },
      body: {
        initial: {
          color: 'text-primary',
          fontSize: 'text-base',
          fontFamily: 'font-poppins',
          fontWeight: 'font-normal',
        },
      },
    },
  },
}

export default accordion
