import type { TabsStylesType } from '@material-tailwind/react'
import type { TabsHeaderStylesType } from '@material-tailwind/react'
import type { TabsBodyStylesType } from '@material-tailwind/react'
import type { TabStylesType } from '@material-tailwind/react'
import type { TabPanelStylesType } from '@material-tailwind/react'

export const tabs: TabsStylesType = {
  defaultProps: {
    className: '',
    orientation: 'horizontal',
  },
  styles: {
    base: {
      overflow: 'overflow-hidden',
    },
    horizontal: {
      display: 'block',
    },
    vertical: {
      display: 'flex',
    },
  },
}

export const tabsHeader: TabsHeaderStylesType = {
  defaultProps: {
    className: '',
  },
  styles: {
    base: {
      display: 'flex',
      position: 'relative',
      bg: 'bg-white',
      bgOpacity: 'bg-opacity-100',
      borderRadius: 'rounded-none',
      p: 'p-1',
      justifyContent: 'justify-center md:justify-start',
    },
    horizontal: {
      flexDirection: 'flex-row',
    },
    vertical: {
      flexDirection: 'flex-col',
    },
  },
}

export const tabsBody: TabsBodyStylesType = {
  defaultProps: {
    animate: {
      unmount: {},
      mount: {},
    },
    className: '',
  },
  styles: {
    base: {
      display: 'block',
      width: 'w-full',
      position: 'relative',
      bg: 'bg-transparent',
      overflow: 'overflow-hidden',
    },
  },
}

export const tab: TabStylesType = {
  defaultProps: {
    className: '',
    activeClassName: '',
    disabled: false,
  },
  styles: {
    base: {
      tab: {
        initial: {
          display: 'flex',
          alignItems: 'items-center',
          justifyContent: 'justify-center md:justify-start',
          textAlign: 'text-center',
          maxWidth: 'max-w-[150px] md:max-w-[220px]',
          width: 'md:w-full',
          height: 'h-full',
          position: 'relative',
          bg: 'bg-transparent',
          py: 'py-1',
          px: 'px-2',
          mx: 'mx-4 md:mx-0',
          color: 'text-blue-gray-900',
          fontSmoothing: '',
          fontFamily: 'font-poppins',
          fontSize: 'text-base',
          fontWeight: 'font-normal',
          lineHeight: 'leading-relaxed',
          userSelect: 'select-none',
          cursor: 'cursor-pointer',
        },
        disabled: {
          opacity: 'opacity-50',
          cursor: 'cursor-not-allowed',
          pointerEvents: 'pointer-events-none',
          userSelect: 'select-none',
        },
      },
      indicator: {
        position: 'absolute',
        inset: 'inset-0',
        zIndex: 'z-10',
        height: 'h-full',
        bg: 'bg-white',
        borderRadius: 'rounded-none',
        boxShadow: 'shadow-none',
        border: 'border border-primary',
      },
    },
  },
}

export const tabPanel: TabPanelStylesType = {
  defaultProps: {
    className: '',
  },
  styles: {
    base: {
      width: 'w-full',
      height: 'h-max',
      color: 'text-gray-700',
      p: 'p-4',
      fontSmoothing: '',
      fontFamily: 'font-poppins',
      fontSize: 'text-base',
      fontWeight: 'font-light',
      lineHeight: 'leading-relaxed',
    },
  },
}
