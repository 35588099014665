import type { TypographyStylesType } from '@material-tailwind/react'

const typography: TypographyStylesType = {
  styles: {
    variants: {
      h1: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
        fontSize: 'text-3xl',
      },
      h2: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
        fontSize: 'text-2xl',
      },
      h3: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
        fontSize: 'text-xl',
        fontWeight: 'font-normal',
      },
      h4: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
        fontSize: 'text-lg',
      },
      h5: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
        fontSize: 'text-base',
        lineHeight: 'leading-[1.5]',
      },
      h6: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
      },
      lead: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
      },
      paragraph: {
        fontFamily: 'font-poppins',
        fontWeight: 'font-normal',
        fontSmoothing: '',
      },
      small: {
        fontFamily: 'font-poppins',
        fontSmoothing: '',
      },
    },
  },
}

export default typography
