import type { AlertStylesType as TailwindAlertStylesType } from '@material-tailwind/react'
import type { variant } from '@material-tailwind/react/types/components/alert'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const alertOutlinedGhost: object

type AlertStylesType =
  | TailwindAlertStylesType
  | {
      defaultProps?: {
        variant?: variant | 'outlined-ghost'
      }
      styles?: {
        variants?: {
          'outlined-ghost'?: typeof alertOutlinedGhost
        }
      }
    }

const alert: AlertStylesType = {
  defaultProps: {
    variant: 'outlined-ghost',
  },
  valid: {
    variants: ['outlined-ghost'],
  },
  styles: {
    base: {
      alert: {
        px: 'px-3',
        py: 'py-3',
        borderRadius: 'rounded-none',
      },
    },
    variants: {
      'outlined-ghost': {
        'blue-gray': {
          border: 'border border-blue-gray-500',
          backgroud: 'bg-blue-gray-500/20',
          color: 'text-blue-gray-900',
        },
        gray: {
          border: 'border border-gray-500',
          backgroud: 'bg-gray-500/20',
          color: 'text-gray-900',
        },
        brown: {
          border: 'border border-brown-500',
          backgroud: 'bg-brown-500/20',
          color: 'text-brown-900',
        },
        'deep-orange': {
          border: 'border border-deep-orange-500',
          backgroud: 'bg-deep-orange-500/20',
          color: 'text-deep-orange-900',
        },
        orange: {
          border: 'border border-orange-500',
          backgroud: 'bg-orange-500/20',
          color: 'text-orange-900',
        },
        amber: {
          border: 'border border-amber-500',
          backgroud: 'bg-amber-500/20',
          color: 'text-amber-900',
        },
        yellow: {
          border: 'border border-yellow-500',
          backgroud: 'bg-yellow-500/20',
          color: 'text-yellow-900',
        },
        lime: {
          border: 'border border-lime-500',
          backgroud: 'bg-lime-500/20',
          color: 'text-lime-900',
        },
        'light-green': {
          border: 'border border-light-green-500',
          backgroud: 'bg-light-green-500/20',
          color: 'text-light-green-900',
        },
        green: {
          border: 'border border-green-500',
          backgroud: 'bg-green-500/20',
          color: 'text-green-900',
        },
        teal: {
          border: 'border border-teal-500',
          backgroud: 'bg-teal-500/20',
          color: 'text-teal-900',
        },
        cyan: {
          border: 'border border-cyan-500',
          backgroud: 'bg-cyan-500/20',
          color: 'text-cyan-900',
        },
        'light-blue': {
          border: 'border border-light-blue-500',
          backgroud: 'bg-light-blue-500/20',
          color: 'text-light-blue-900',
        },
        blue: {
          border: 'border border-blue-500',
          backgroud: 'bg-blue-500/20',
          color: 'text-blue-900',
        },
        indigo: {
          border: 'border border-indigo-500',
          backgroud: 'bg-indigo-500/20',
          color: 'text-indigo-900',
        },
        'deep-purple': {
          border: 'border border-deep-purple-500',
          backgroud: 'bg-deep-purple-500/20',
          color: 'text-deep-purple-900',
        },
        purple: {
          border: 'border border-purple-500',
          backgroud: 'bg-purple-500/20',
          color: 'text-purple-900',
        },
        pink: {
          border: 'border border-pink-500',
          backgroud: 'bg-pink-500/20',
          color: 'text-pink-900',
        },
        red: {
          border: 'border border-red-500',
          backgroud: 'bg-red-500/20',
          color: 'text-red-900',
        },
      },
    },
  },
}

export default alert
