'use client'

import { createContext, useContext, ReactNode } from 'react'
import { Dictionary } from '@/types'

export const DictionaryContext = createContext({})

export function DictionaryProvider({
  children,
  dictionary,
}: {
  children: ReactNode
  dictionary: Dictionary
}) {
  return (
    <DictionaryContext.Provider value={dictionary}>
      {children}
    </DictionaryContext.Provider>
  )
}

const SEPARATOR = '.'
const PREFIX = 'ngo_trans'

const findByPath = (
  path: string[] = [],
  dictionary: Dictionary = {}
): string | null => {
  const key = path.shift()
  if (!key || !dictionary || !(key in dictionary)) {
    return null
  }

  if (typeof dictionary[key] === 'string') {
    return dictionary[key] as string
  }

  return findByPath(path, dictionary[key] as Dictionary)
}

export function useDictionary(namespace: string = '') {
  const dictionary = useContext<Dictionary>(DictionaryContext)

  return (key: string, params?: { [key: string]: string }): string => {
    const compositeKey = (namespace ? namespace + SEPARATOR : '') + key,
      path = compositeKey.split(SEPARATOR)
    let text = findByPath(path, dictionary)

    if (text === null) {
      return PREFIX + SEPARATOR + compositeKey
    }

    if (params) {
      text = text.replace(/{(\w+)}/g, (match, key) => params[key] || match)
    }

    return text
  }
}
