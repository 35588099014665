import type { TextareaStylesType } from '@material-tailwind/react'

const textarea: TextareaStylesType = {
  defaultProps: {
    variant: 'outlined',
  },
  styles: {
    base: {
      textarea: {
        bg: 'bg-white',
        color: 'text-black',
      },
      label: {
        color: 'text-black',
        display: 'hidden',
        width: '!auto',
      },
    },
    variants: {
      outlined: {
        base: {
          textarea: {
            borderRadius: 'rounded-none',
            placeholder: 'opacity-100',
            borderWidth: 'border',
            borderColor: 'border-border-color',
            floated: {
              borderWidth: 'border focus:border',
              borderColor: '',
            },
          },
        },
        sizes: {
          md: {
            textarea: {
              fontSize: 'text-base',
            },
          },
          lg: {
            textarea: {
              fontSize: 'text-base',
            },
          },
        },
      },
      standard: {},
      static: {
        base: {
          textarea: {
            borderRadius: 'rounded-none',
            placeholder: 'opacity-100',
            borderWidth: 'border',
            borderColor: 'border-border-color',
            padding: 'pl-2.5',
            paddingRight: '!pr-10',
            paddingTop: '!pt-2.5',
            paddingBottom: '!pb-2.5',
            floated: {
              borderWidth: 'border focus:border',
              borderColor: '',
            },
          },
          label: {
            display: '!inline-block',
            fontSize: 'text-base',
            top: '!top-0',
            before: {
              content: 'before:content-none',
            },
            after: {
              content: 'after:content-none',
            },
          },
        },
        sizes: {
          md: {
            textarea: {
              fontSize: 'text-base',
              pt: 'pt-4',
              pb: 'pb-1.5',
              mt: 'mt-6',
            },
            label: {
              fontSize: 'text-base',
              before: {
                content: 'before:content-none',
              },
              after: {
                content: 'after:content-none',
              },
            },
          },
          lg: {
            label: {
              fontSize: 'text-base',
              top: '!top-0',
            },
          },
        },
      },
    },
  },
}

export default textarea
