'use client'

import { ReactNode } from 'react'
import { Session } from 'next-auth'
import { SessionProvider } from 'next-auth/react'

interface AuthProviderProps {
  children: ReactNode
  pageProps?: {
    session?: Session | null
  }
}

export function AuthProvider({ children, pageProps }: AuthProviderProps) {
  // Provide a default value for pageProps if it's undefined
  const { session } = pageProps || {}

  return <SessionProvider session={session}>{children}</SessionProvider>
}
