import type { BreadcrumbsStyleTypes } from '@material-tailwind/react'

const breadcrumbs: BreadcrumbsStyleTypes = {
  styles: {
    base: {
      root: {
        initial: {
          width: 'w-full',
          overflow: 'overflow-x-auto',
          scrollbar: 'no-scrollbar',
        },
      },
      list: {
        flexWrap: 'flex-nowrap',
        bg: 'bg-transparent',
        pt: 'pt-2',
        py: 'py-4',
        px: 'px-0',
        gap: 'gap-1.5 md:gap-2.5',
      },
      item: {
        initial: {
          whiteSpace: 'whitespace-nowrap',
          gap: 'gap-1 md:gap-2',
          color: 'text-primary',
          fontFamily: 'font-poppins',
          hover: 'hover:text-secondary',
          last: 'last:pointer-events-none',
        },
        disabled: {
          color: 'text-secondary',
        },
      },
      separator: {
        color: 'text-primary',
        px: 'mx-1',
      },
    },
  },
}

export default breadcrumbs
